import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { TextButton, TextButtonPriority, Text } from 'wix-ui-tpa';
import ShowMoreText from 'react-show-more-text';
import settingsParams from '../../../settingsParams';
import s from './ProductDescription.scss';
import { useControllerProps } from '../../ControllerContext';
import { Property } from 'csstype/index';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { SPECS } from '../../../../../consts';

export const ProductDescriptionDataHook = {
  description: 'ProductDescription.description',
};

const ProductDescription = () => {
  const settings = useSettings();
  const { product } = useControllerProps().giftCardStore;
  const { experiments } = useExperiments();

  const description = product?.description;
  const shouldUseNewGiftCardDesignCapabilities = experiments.enabled(
    SPECS.GiftCardDesignCapabilities,
  );

  const moreButton = (
    <>
      <div></div>
      <TextButton
        data-hook="rise-gift-card-product-description-read-more-button"
        priority={TextButtonPriority.link}
      >
        {settings.get(settingsParams.descriptionReadMoreButton)}
      </TextButton>
    </>
  );

  const lessButton = (
    <>
      <div></div>
      <TextButton
        data-hook="rise-gift-card-product-description-less-button"
        priority={TextButtonPriority.link}
      >
        {settings.get(settingsParams.descriptionReadLessButton)}
      </TextButton>
    </>
  );

  const textAlignment = shouldUseNewGiftCardDesignCapabilities
    ? settings.get(settingsParams.productTitleAlignment)
    : settings.get(settingsParams.descriptionAlignment);

  return shouldUseNewGiftCardDesignCapabilities ? (
    <div
      className={s.description}
      style={{
        textAlign: settings.get(
          settingsParams.productTitleAlignment,
        ) as Property.TextAlign,
      }}
    >
      <p>
        <span data-hook={ProductDescriptionDataHook.description}>
          {description}
        </span>
        <div style={{ display: 'none' }}>{description}</div>
      </p>
    </div>
  ) : (
    <div
      className={s.description}
      style={{
        textAlign: textAlignment as Property.TextAlign,
      }}
    >
      <p>
        {/* @ts-expect-error */}
        <ShowMoreText
          lines={settings.get(settingsParams.descriptionLimitRows)}
          more={moreButton}
          less={lessButton}
          expanded={false}
          keepNewLines={true}
        >
          {description}
        </ShowMoreText>

        <div style={{ display: 'none' }}>{description}</div>
      </p>
    </div>
  );
};

export default ProductDescription;
