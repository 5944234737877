import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

import { Alignments } from './Settings/Components/Alignment/Alignment';
import { SPECS } from '../../consts';

export type ISettingsParams = {
  descriptionLimitRows: SettingsParamType.Number;
  deliveryDate: SettingsParamType.Boolean;
  quantity: SettingsParamType.Boolean;
  productTitleAlignment: SettingsParamType.String;
  descriptionAlignment: SettingsParamType.String;
  buyNowButtonText: SettingsParamType.Text;
  showBuyNowButton: SettingsParamType.Boolean;
  showAddToCartButton: SettingsParamType.Boolean;
  addToCartButtonText: SettingsParamType.Text;
  amountLabel: SettingsParamType.Text;
  recipientEmailLabel: SettingsParamType.Text;
  enterCustomAmountLabel: SettingsParamType.Text;
  otherAmountVariantLabel: SettingsParamType.Text;
  deliveryDateLabel: SettingsParamType.Text;
  deliveryDateDefaultText: SettingsParamType.Text;
  recipientNameLabel: SettingsParamType.Text;
  giftMessageLabel: SettingsParamType.Text;
  quantityLabel: SettingsParamType.Text;
  descriptionReadMoreButton: SettingsParamType.Text;
  descriptionReadLessButton: SettingsParamType.Text;
  amountErrorMessage: SettingsParamType.Text;
  recipientEmailErrorMessage: SettingsParamType.Text;
  srDeliveryDateChooseDateButton: SettingsParamType.Text;
  srQuantityRemoveQuantity: SettingsParamType.Text;
  srQuantityTotalQuantity: SettingsParamType.Text;
  srQuantityAddQuantity: SettingsParamType.Text;
  quantityErrorMessage: SettingsParamType.Text;
  giftCardForLabel: SettingsParamType.Text;
  someoneElseButton: SettingsParamType.Text;
  myselfButton: SettingsParamType.Text;
  giftCardFor: SettingsParamType.Text;
  addToCartPostAction: SettingsParamType.Text;
  displayTitle: SettingsParamType.Boolean;
  displayDescription: SettingsParamType.Boolean;
  displayAmountOptions: SettingsParamType.Boolean;
  displayQuantitySelector: SettingsParamType.Boolean;
  displayImage: SettingsParamType.Boolean;
};

export default createSettingsParams<ISettingsParams>({
  descriptionLimitRows: {
    type: SettingsParamType.Number,
    getDefaultValue: () => 3,
  },
  productTitleAlignment: {
    type: SettingsParamType.String,
    getDefaultValue: () => Alignments.LEFT,
  },
  descriptionAlignment: {
    type: SettingsParamType.String,
    getDefaultValue: () => Alignments.LEFT,
  },
  deliveryDate: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  quantity: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showBuyNowButton: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showAddToCartButton: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  buyNowButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t, experiments }) => {
      return experiments.enabled(SPECS.GiftCardDesignCapabilities)
        ? `${t('settings.giftCardPage.textTab.buyNow.defaultText')}`
        : `${t('giftCardPage.buyNow.button')}`;
    },
  },
  addToCartButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t, experiments }) => {
      return experiments.enabled(SPECS.GiftCardDesignCapabilities)
        ? `${t('settings.giftCardPage.textTab.addToCart.defaultText')}`
        : `${t('giftCardPage.addToCart.button')}`;
    },
  },
  amountLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t, experiments }) => {
      return experiments.enabled(SPECS.GiftCardDesignCapabilities)
        ? `${t('settings.giftCardPage.textTab.amountOption.defaultText')}`
        : `${t('giftCardPage.amount.label')}`;
    },
  },
  recipientEmailLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t, experiments }) => {
      return experiments.enabled(SPECS.GiftCardDesignCapabilities)
        ? `${t('settings.giftCardPage.textTab.email.defaultText')}`
        : `${t('giftCardPage.recipientEmail.label')}`;
    },
  },
  enterCustomAmountLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t, experiments }) => {
      return experiments.enabled(SPECS.GiftCardDesignCapabilities)
        ? `${t('settings.giftCardPage.textTab.enterCustomAmount.defaultText')}`
        : `${t('giftCardPage.enterAmount.label')}`;
    },
  },
  otherAmountVariantLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t, experiments }) => {
      return experiments.enabled(SPECS.GiftCardDesignCapabilities)
        ? `${t('settings.giftCardPage.textTab.customAmount.defaultText')}`
        : `${t('giftCardPage.amount.other.label')}`;
    },
  },
  deliveryDateLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t, experiments }) => {
      return experiments.enabled(SPECS.GiftCardDesignCapabilities)
        ? `${t('settings.giftCardPage.textTab.date.title')}`
        : `${t('giftCardPage.deliveryDate.label')}`;
    },
  },
  deliveryDateDefaultText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.deliveryDate.defaultText')}`,
  },
  recipientNameLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t, experiments }) => {
      return experiments.enabled(SPECS.GiftCardDesignCapabilities)
        ? `${t('settings.giftCardPage.textTab.name.defaultText')}`
        : `${t('giftCardPage.recipientName.label')}`;
    },
  },
  giftMessageLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t, experiments }) => {
      return experiments.enabled(SPECS.GiftCardDesignCapabilities)
        ? `${t('settings.giftCardPage.textTab.customMessage.defaultText')}`
        : `${t('giftCardPage.giftMessage.label')}`;
    },
  },
  quantityLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t, experiments }) => {
      return experiments.enabled(SPECS.GiftCardDesignCapabilities)
        ? `${t('settings.giftCardPage.textTab.quantity.defaultText')}`
        : `${t('giftCardPage.quantity.label')}`;
    },
  },
  descriptionReadMoreButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('giftCardPage.description.readMore.button')}`,
  },
  descriptionReadLessButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('giftCardPage.description.readLess.button')}`,
  },
  amountErrorMessage: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.amount.errorMessage')}`,
  },
  recipientEmailErrorMessage: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('giftCardPage.recipientEmail.errorMessage')}`,
  },
  srDeliveryDateChooseDateButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('giftCardPage.sr.deliveryDate.chooseDate.button')}`,
  },
  srQuantityRemoveQuantity: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('giftCardPage.sr.quantity.removeQuantity')}`,
  },
  srQuantityTotalQuantity: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('giftCardPage.sr.quantity.totalQuantity')}`,
  },
  srQuantityAddQuantity: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.sr.quantity.addQuantity')}`,
  },
  quantityErrorMessage: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.quantity.errorMessage')}`,
  },
  giftCardForLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t, experiments }) => {
      return experiments.enabled(SPECS.GiftCardDesignCapabilities)
        ? `${t('settings.giftCardPage.textTab.recipient.defaultText')}`
        : `${t('giftCardPage.giftCardFor.label')}`;
    },
  },
  someoneElseButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t, experiments }) => {
      return experiments.enabled(SPECS.GiftCardDesignCapabilities)
        ? `${t('settings.giftCardPage.textTab.forSomeoneElse.defaultText')}`
        : `${t('giftCardPage.someoneElse.button')}`;
    },
  },
  myselfButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t, experiments }) => {
      return experiments.enabled(SPECS.GiftCardDesignCapabilities)
        ? `${t('settings.giftCardPage.textTab.forMyself.defaultText')}`
        : `${t('giftCardPage.myself.button')}`;
    },
  },
  giftCardFor: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `both`,
  },
  addToCartPostAction: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `openSideCart`,
  },
  displayTitle: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  displayDescription: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  displayAmountOptions: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  displayQuantitySelector: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  displayImage: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
});
