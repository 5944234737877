import React from 'react';
import { useEnvironment, useBi, useExperiments } from '@wix/yoshi-flow-editor';
import { SPECS } from '../../../../../consts';
import { useSettings } from '@wix/tpa-settings/react';
import s from './ProductBuyForSelection.scss';
import { classes } from './ProductBuyForSelection.st.css';
import cx from 'classnames';
import settingsParams from '../../../settingsParams';
import { BoxSelection, BoxSelectionBoxSize } from 'wix-ui-tpa';
import { useState } from '../../stores';

export enum ProductBuyForSelectionDataHooks {
  boxSelection = 'ProductBuyForSelection.boxSelection',
}

const ProductBuyForSelection = () => {
  const {
    state: { checkout },
    dispatch,
  } = useState();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const bi = useBi();
  const { experiments } = useExperiments();
  const shouldUseNewGiftCardDesignCapabilities = experiments.enabled(
    SPECS.GiftCardDesignCapabilities,
  );

  return (
    <>
      <fieldset className={s.textFieldContainer}>
        <legend className={s.label}>
          {settings.get(settingsParams.giftCardForLabel)}
        </legend>
        <BoxSelection
          data-hook={ProductBuyForSelectionDataHooks.boxSelection}
          name="buyfor-selection"
          className={
            shouldUseNewGiftCardDesignCapabilities
              ? classes.boxSelection
              : classes.boxSelectionOld
          }
          size={BoxSelectionBoxSize.small}
          onChange={({ id }) => {
            bi.report({
              evid: 54,
              src: 125,
              endpoint: 'gc-client-sf',
              params: {
                button: id,
              },
            });
            return dispatch({
              type: 'setPurchaseOptions',
              payload: {
                isGift: id === 'someoneElse',
              },
            });
          }}
        >
          <BoxSelection.Option
            className={cx(
              s.BoxSelectionOption,
              isMobile && s.BoxSelectionOptionMobile,
            )}
            id="someoneElse"
            checked={checkout.purchaseOptions.isGift}
          >
            {settings.get(settingsParams.someoneElseButton)}
          </BoxSelection.Option>
          <BoxSelection.Option
            className={s.BoxSelectionOption}
            id="myself"
            checked={!checkout.purchaseOptions.isGift}
          >
            {settings.get(settingsParams.myselfButton)}
          </BoxSelection.Option>
        </BoxSelection>
      </fieldset>
    </>
  );
};

export default ProductBuyForSelection;
