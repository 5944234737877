import React from 'react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import s from './ProductImage.scss';
import cx from 'classnames';
import { DefaultImageIcon } from './DefaultImageIcon';
import {
  Image,
  ImageLoadingBehaviorOptions,
  ImageHoverEffectOptions,
  ImageResizeOptions,
} from 'wix-ui-tpa';
import { useControllerProps } from '../../ControllerContext';
import { useState } from '../../stores';
import {
  DEFAULT_IMAGE_WIDTH_DESKTOP,
  DEFAULT_IMAGE_WIDTH_MOBILE,
  SPECS,
} from '../../../../../consts';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../stylesParams';
import {
  ratioValueMap,
  RatioValue,
} from '../../../Settings/Tabs/DesignTab/consts';

export enum ProductImageDataHook {
  image = 'ProductImageDataHook.image',
  defaultImage = 'ProductImageDataHook.defaultImage',
}
const ProductImage = () => {
  const styles = useStyles();
  const {
    state: { checkout },
  } = useState();
  const { isMobile, dimensions } = useEnvironment();
  const { product, presetVariants } = useControllerProps().giftCardStore;
  const { experiments } = useExperiments();
  const selectedVariant = presetVariants.find(
    (variant) => variant.id === checkout.purchaseOptions.variantId,
  );

  const shouldUseNewGiftCardDesignCapabilities = experiments.enabled(
    SPECS.GiftCardDesignCapabilities,
  );

  const width = isMobile
    ? (dimensions?.width || DEFAULT_IMAGE_WIDTH_MOBILE) * 0.96
    : DEFAULT_IMAGE_WIDTH_DESKTOP;
  const imageMedia = selectedVariant?.media || product?.media;
  const imageRatioFromSettings =
    ratioValueMap[styles.get(stylesParams.imageRatio) as RatioValue];
  const aspectRatio = imageMedia && imageMedia.width / imageMedia.height;

  const DefaultImage = () => {
    return (
      <div
        data-hook={ProductImageDataHook.defaultImage}
        className={cx(
          s.defaultImage,
          shouldUseNewGiftCardDesignCapabilities
            ? s.imageContainer
            : s.imageContainerOld,
          isMobile && s.defaultImageMobile,
        )}
      >
        <DefaultImageIcon />
      </div>
    );
  };

  return product && imageMedia?.url ? (
    <Image
      data-hook={ProductImageDataHook.image}
      className={
        shouldUseNewGiftCardDesignCapabilities
          ? s.imageContainer
          : s.imageContainerOld
      }
      key={imageMedia.url}
      src={imageMedia.url}
      width={width}
      alt={imageMedia.alt_image}
      resize={
        shouldUseNewGiftCardDesignCapabilities
          ? ImageResizeOptions.cover
          : ImageResizeOptions.contain
      }
      aspectRatio={
        shouldUseNewGiftCardDesignCapabilities
          ? imageRatioFromSettings || aspectRatio
          : aspectRatio
      }
      loadingBehavior={ImageLoadingBehaviorOptions.blur}
      hoverEffect={ImageHoverEffectOptions.zoom}
    />
  ) : (
    <DefaultImage />
  );
};

export default ProductImage;
