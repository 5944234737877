import React from 'react';
import { InputDialog, Text, Button } from 'wix-ui-tpa/cssVars';
import { ButtonPriority, TextTypography } from 'wix-ui-tpa';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { classes } from './NoPremiumModal.st.css';
import cx from 'classnames';

const CustomFooter: React.FC<{
  onCtaClick: () => void;
  ctaText: string;
  isMobile: boolean;
}> = ({ onCtaClick, ctaText, isMobile }) => {
  return (
    <div className={classes.footerContainer}>
      <Button
        className={cx(isMobile && classes.ctaButtonMobile)}
        upgrade
        priority={ButtonPriority.basic}
        onClick={onCtaClick}
        fullWidth={isMobile}
      >
        {ctaText}
      </Button>
    </div>
  );
};

export const NoPremiumModal: React.FC<{
  isOpen: boolean;
  onModalClose: () => void;
}> = ({ isOpen, onModalClose }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const handleCloseModal = () => {
    onModalClose();
  };

  return (
    <InputDialog
      className={classes.noPremiumModal}
      onClose={handleCloseModal}
      isOpen={isOpen}
      withoutDivider
      customFooter={
        <CustomFooter
          onCtaClick={handleCloseModal}
          ctaText={t('giftCards.nonPremiumSite.errorModal.cta')}
          isMobile={isMobile}
        />
      }
    >
      <div
        className={cx(
          isMobile ? classes.modalContentMobile : classes.modalContent,
        )}
      >
        <Text
          typography={
            isMobile ? TextTypography.smallTitle : TextTypography.largeTitle
          }
          tagName="div"
        >
          {t('giftCards.nonPremiumSite.errorModal.title')}
        </Text>
        <Text
          typography={TextTypography.runningText}
          className={classes.subtitle}
          tagName="div"
        >
          {t('giftCards.nonPremiumSite.errorModal.subtitle')}
        </Text>
      </div>
    </InputDialog>
  );
};
export default NoPremiumModal;
